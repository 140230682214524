/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.split.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
window.onload = function () {};

import { Dialog, Toast } from 'vant';
import TransferDom from 'vux/src/directives/transfer-dom';
export default {
  name: 'OrderShareDetail',
  directives: {
    TransferDom: TransferDom
  },
  // 进入路由前判断来源
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    // 从结算页面跳转过来
    if (from.name !== 'OrderPay') {
      window.vm.$utils.Store.setItem('showOrders', false);
      next();
    } else {
      window.vm.$utils.Store.setItem('showOrders', true);
      next();
    }
  },
  data: function data() {
    return {
      spareCol1: '',
      goodsdetail: [],
      foodsdetail: [],
      showPay: true,
      orderDate: '',
      shareTime: '',
      //共享时间
      orderState: '',
      orderId: '',
      orderNum: '',
      addrName: '山东威尔数据',
      sendFlag: false,
      //需否送餐
      showOrders: this.$utils.Store.getItem('showOrders'),
      mealId: null,
      operate: '0',
      takeFlag: '0',
      cancelFlag: '',
      placeName: '',
      subOrderId: '',
      shareOrderId: '',
      userName: '',
      mealName: '',
      orderPrice: '',
      ctDate: '',
      week: '',
      weekDay: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      AllPageHeight: '',
      //当前页面高度
      windowHeight: '',
      //可视高度
      loadingend: true //当前页面数据是否加载完毕

    };
  },
  watch: {
    AllPageHeight: function AllPageHeight(val) {
      if (this.windowHeight > val) {
        document.querySelector('.ShareDetail').style.height = this.windowHeight + 'px';
      }
    }
  },
  methods: {
    //撤销订单
    cancelOrder: function cancelOrder() {
      var _this = this;

      //开启二次确认
      this.$utils.Tools.confirm(function () {
        _this.$api.Meal.updateByPrimaryKey({
          subOrderId: _this.orderId,
          mealState: '2'
        }).then(function (response) {
          _this.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg; //返回错误状态码,显示返回错误信息

          if (+code !== _this.$code.success) {
            _this.$utils.Tools.toastShow(msg);
          } else {
            //返回正确状态码,显示返回信息
            _this.$utils.Tools.toastShow(msg); // 延迟一秒跳转至订单页面


            setTimeout(function () {
              _this.$router.replace('/OrderRecord');
            }, 1000);
          }
        }).catch(function () {
          _this.$utils.Tools.httpError();
        });
      }, '确定撤销订单吗？');
    },
    // 手动取餐
    getOrder: function getOrder() {
      var _this2 = this;

      this.$utils.Tools.confirm(function () {
        _this2.$api.Meal.updateByPrimaryKey({
          subOrderId: _this2.orderId,
          mealState: '3'
        }).then(function (response) {
          _this2.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg;

          if (+code !== _this2.$code.success) {
            _this2.$utils.Tools.toastShow(msg);
          } else {
            _this2.$utils.Tools.toastShow(msg);

            setTimeout(function () {
              _this2.$router.replace('/OrderRecord');
            }, 1000);
          }
        }).catch(function () {
          _this2.$utils.Tools.httpError();
        });
      }, '确定手动取餐吗？');
    },
    // 获取订单的详情信息
    getOrderDetail: function getOrderDetail() {
      var _this3 = this;

      this.$utils.Tools.loadShow();
      this.$api.Meal.getOrderDetail(this.orderId, this.mealId).then(function (response) {
        // this.$utils.Tools.loadHide()
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.goodsdetail = data.mealList;
          _this3.spareCol1 = _this3.goodsdetail[0].spareCol1;
          _this3.foodsdetail = data.foodList;
          _this3.addrName = data.addrName;
          _this3.orderDate = data.orderDate;
          _this3.shareTime = data.shareTime;
          _this3.orderState = data.orderState;
          _this3.orderId = data.orderId;
          _this3.shareOrderId = _this3.goodsdetail[0].orderId;
          _this3.orderPrice = data.orderPrice;
          _this3.orderNum = data.foodNum;
          _this3.sendFlag = data.sendFlag;
          _this3.operate = data.operate;
          _this3.takeFlag = data.takeFlag;
          _this3.cancelFlag = data.cancelFlag;
          _this3.placeName = data.placeName;
          _this3.subOrderId = data.subOrderId;
          _this3.userName = data.userName;
          _this3.mealName = _this3.goodsdetail[0].mealName;
          _this3.orderPrice = data.orderPrice;
          _this3.ctDate = data.ctDate;

          _this3.fade();

          var date = new Date(_this3.orderDate);
          _this3.week = _this3.weekDay[date.getDay()];
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    historyClick: function historyClick() {
      this.$router.push('/OrderRecord');
    },
    fade: function fade() {},
    toDecimal2: function toDecimal2(x) {
      var f = parseFloat(x);

      if (isNaN(f)) {
        return false;
      }

      f = Math.round(x * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf('.');

      if (rs < 0) {
        rs = s.length;
        s += '.';
      }

      while (s.length <= rs + 2) {
        s += '0';
      }

      return s;
    },
    toFont: function toFont(num) {
      num = num.substring(0, 10);
      return num;
    },
    // 领取共享订单并支付
    getShare: function getShare() {
      var _this4 = this;

      Dialog.confirm({
        title: '提示',
        message: '确定领取此订单并进行支付吗？',
        confirmButtonColor: '#3399ff'
      }).then(function () {
        _this4.$api.Meal.receiveShareOrder({
          subOrderId: _this4.subOrderId,
          orderId: _this4.shareOrderId,
          mealId: _this4.mealId,
          userSerial: _this4.$utils.Store.getItem('userSerial'),
          userName: _this4.userName,
          mealName: _this4.mealName,
          orderPrice: _this4.orderPrice
        }).then(function (response) {
          _this4.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg;

          if (+code !== _this4.$code.success) {
            _this4.$utils.Tools.toastShow(msg);

            _this4.finished = true;
          } else {
            _this4.$utils.Tools.loadHide();

            var _code = response.code,
                _msg = response.msg;

            if (+_code !== _this4.$code.success) {
              _this4.$utils.Tools.toastShow(_msg);

              _this4.finished = true;
            } else {
              Toast('订单领取成功');

              _this4.$router.push({
                path: '/OrderRecord'
              });

              _this4.onRefresh();
            }
          }
        }).catch(function () {
          _this4.$utils.Tools.httpError();
        });
      }).catch(function () {// on cancel
        // Toast('领取失败，请稍后重试')
      });
    }
  },
  beforeCreate: function beforeCreate() {
    this.$utils.Tools.loadShow();
  },
  created: function created() {
    var _this5 = this;

    this.$utils.Tools.loadShow();
    this.windowHeight = '';
    this.AllPageHeight = '';
    setTimeout(function () {
      _this5.windowHeight = document.querySelector('body').clientHeight;
      _this5.AllPageHeight = document.getElementsByClassName('ShareDetail')[0].clientHeight;

      if (_this5.windowHeight > _this5.AllPageHeight) {
        document.querySelector('.ShareDetail').style.height = _this5.windowHeight + 'px';
      }

      _this5.$utils.Tools.loadHide();
    }, 1000);
    var strs = [];

    if (this.$route.query.orderId) {
      this.orderId = this.$route.query.orderId;
      this.mealId = this.$route.query.mealId;
    } else {
      this.$utils.Store.setItem('state', this.$route.params.state);
      strs = decodeURI(this.$route.params.state).split(',');
      this.orderId = strs[0];
      this.mealId = strs[1];
    }

    this.getOrderDetail();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {
    this.$utils.Store.removeItem('state');
  }
};